// import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css'; // Import Bootstrap CSS
import 'font-awesome/css/font-awesome.min.css'; // Import FontAwesome icons
// import {useTranslation} from "react-i18next";


function App() {
//   const { t } = useTranslation();

//   return <h2>{t('Welcome to React')}</h2>;
}

export default App;
